.newacy-section2-card {
    background-color: #fff;
    border-radius: 10px;
    padding: 25px;
    margin-bottom: 40px;
    box-shadow: 0px 0px 10px -5px #333;
}

.newacy-section2-card-image-wrap img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
}

.newacy-section2-card-text-title * {
    font-weight: 800;
    color: var(--default-red);
    text-align: center;
    margin-bottom: 40px;
}

.newacy-section2-card-text-description {
    color: var(--dark-gray);
    text-align: justify;
}

@media (min-width: 768px) {
    .newacy-section2-card-text-title * {
        text-align: left;
    }
    .newacy-section2-card-text-description {
        text-align: left;
    }
}