@media (min-width: 768px) {
    .newacy-hamburger-menu-body,
    .hamburger-menu-bar-wrap {
        display: none;
    }
    .newacy-navbar {
        content: '';
        width: 100%;
        height: 8vh;
        max-height: 80px;
        background-color: transparent;
        padding: 15px;
        position: absolute;
        top: 0;
        left: 0;
        z-index: var(--z-index-max);
    }
    .newacy-navbar * {
        max-height: 50px !important;
    }
    .newacy-navbar-over {
        background-color: transparent;
    }
    .newacy-navbar-logo-container {
        background-color: #fff;
        border-radius: 10px;
        padding: 0px 10px;
        text-align: center;
    }
    .newacy-navbar-logo-container img {
        max-width: 100%;
    }
    .newacy-navbar-wrap {
        width: 100%;
        padding: 15px;
    }
    .newacy-navbar-wrap-link-box {
        float: right;
    }
    .newacy-navbar-wrap a {
        color: #fff;
        font-weight: 700;
        padding: 0px 25px;
        text-decoration: none;
    }
    .newacy-navbar-link-bg {
        background-color: #fff;
        transition: all 0.5s ease-in-out;
    }
    .newacy-navbar-link-bg:hover {
        background-color: var(--default-red);
        transition: all 0.5s ease-in-out;
    }
    .newacy-navbar-wrap .newacy-navbar-link-bg {
        color: var(--default-blue);
        font-weight: 700;
        padding: 10px 25px;
        text-decoration: none;
        border-radius: 10px;
    }
    .newacy-navbar-wrap .newacy-navbar-link-bg:hover {
        color: #fff;
        font-weight: 700;
        padding: 10px 25px;
        text-decoration: none;
        border-radius: 10px;
    }
}