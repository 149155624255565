.newacy-banner-background-image::before {
    background-image: url('../assets/background_200.jpg');
}

@media (min-width: 200px) {
    .newacy-banner-background-image::before {
        background-image: url('../assets/background_592.jpg');
    }
}

@media (min-width: 592px) {
    .newacy-banner-background-image::before {
        background-image: url('../assets/background_857.jpg');
    }
}

@media (min-width: 857px) {
    .newacy-banner-background-image::before {
        background-image: url('../assets/background_1089.jpg');
    }
}

@media (min-width: 1089px) {
    .newacy-banner-background-image::before {
        background-image: url('../assets/background_1218.jpg');
    }
}

@media (min-width: 1218px) {
    .newacy-banner-background-image::before {
        background-image: url('../assets/background_1318.jpg');
    }
}

@media (min-width: 1318px) {
    .newacy-banner-background-image::before {
        background-image: url('../assets/background_1400.jpg');
    }
}

@media (min-width: 1400px) {
    .newacy-banner-background-image::before {
        background-image: url('../assets/background.jpg');
    }
}