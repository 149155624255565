@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
:root {
    --z-index-max: 9999999;
    --z-index-high: 999999;
    --z-index-medium: 99999;
    --z-index-lower: 9999;
}

body * {
    font-family: 'Montserrat', sans-serif;
    color: var(--default-secondary);
}

#root {
    max-width: 100vw;
    overflow: hidden;
}

.btn-primary,
.btn-primary:hover,
.btn-primary:active,
.btn-primary:visited,
.btn-primary:focus {
    background-color: var(--default-blue);
    border: var(--default-blue);
    outline: 0px !important;
}

.btn-primary * {
    color: #fff;
}