.newacy-section1-card {
    background-color: var(--high-gray);
    text-align: center;
    border-radius: 10px;
    box-shadow: 0px 0px 10px -5px #333;
    padding: 10px;
    padding-top: 20px;
    transition: all 1s ease-in-out;
    height: 100%;
}

.newacy-section1-card-icon img {
    width: 50px;
    height: 50px;
}

.newacy-section1-card:hover {
    transition: all 1s ease-in-out;
    height: auto;
}

.newacy-section1-card:hover * {
    opacity: 1;
}

.newacy-section1-card:hover {
    background-color: var(--high-gray);
}

.newacy-section1-card-title * {
    color: var(--default-blue);
    font-weight: 600;
}

.newacy-section1-card:hover .newacy-section1-card-icon {
    opacity: 0;
    display: none;
}

.newacy-section1-card-description {
    opacity: 0;
    display: none;
}

.newacy-section1-card:hover .newacy-section1-card-description {
    display: block;
}

.newacy-section1-card:hover .newacy-section1-card-description p {
    opacity: 1;
}

.newacy-section1-card:hover .newacy-section1-card-description a,
.newacy-section1-card:hover .newacy-section1-card-description a {
    text-decoration: none;
    color: var(--default-blue);
}

.newacy-section1-card p {
    opacity: 0;
    color: var(--default-blue);
}


/* blue */

.newacy-section1-card-blue {
    background-color: var(--default-blue);
    text-align: center;
    border-radius: 10px;
    box-shadow: 0px 0px 10px -5px #333;
    padding: 10px;
    padding-top: 20px;
    transition: all 1s ease-in-out;
    height: 100%;
}

.newacy-section1-card-blue:hover {
    transition: all 1s ease-in-out;
    height: auto;
}

.newacy-section1-card-blue:hover * {
    opacity: 1;
}

.newacy-section1-card-blue:hover {
    background-color: var(--high-gray);
}

.newacy-section1-card-blue .newacy-section1-card-title * {
    color: var(--high-gray);
    font-weight: 600;
}

.newacy-section1-card-blue:hover .newacy-section1-card-icon {
    opacity: 0;
    display: none;
}

.newacy-section1-card-blue:hover .newacy-section1-card-description {
    display: block;
}

.newacy-section1-card-blue:hover .newacy-section1-card-description p {
    opacity: 1;
    display: block;
    color: var(--default-blue) !important;
}

.newacy-section1-card-blue .newacy-section1-card {
    color: var(--default-blue);
}

@media (max-width: 767px) {
    .newacy-section1-card-blue .newacy-touch-suggest {
        position: relative;
        width: 100%;
        height: 100%;
        background-image: url('../assets/touch_suggest.svg');
        background-position: 100% 1%;
        background-size: 40px 40px;
        background-repeat: no-repeat;
    }
    .newacy-section1-card-blue .newacy-touch-suggest::before {
        position: absolute;
        z-index: 1;
        content: '';
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-image: url('../assets/touch_suggest.png');
        background-position: calc(100%) calc(1% + 20px);
        background-size: 30px 30px;
        background-repeat: no-repeat;
    }
    .newacy-section1-card-blue:hover .newacy-touch-suggest,
    .newacy-section1-card-blue:hover .newacy-touch-suggest::before {
        width: 100%;
        height: 100%;
        background-image: none;
        background-position: 100% 1%;
        background-size: 20px 20px;
        background-repeat: no-repeat;
    }
}

@media (min-width: 768px) {
    .newacy-section1-card:hover {
        transition: all 1s ease-in-out;
        position: absolute;
        z-index: 1;
    }
    .newacy-section1-card-blue:hover {
        transition: all 1s ease-in-out;
        position: absolute;
        z-index: 1;
    }
}