.newacy-footer {
    background-color: var(--default-blue);
    padding-top: 100px;
    padding-bottom: 100px;
}

.footer-logo img {
    width: 100%;
    max-height: 100%;
}

.footer-bottom-wrap * {
    text-align: center;
    color: var(--high-gray);
}

.footer-bottom-wrap a:hover {
    color: var(--high-blue);
}