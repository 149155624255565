.newacy-section4 {
    margin-top: 50px;
    margin-bottom: 30px;
    background-color: var(--high-gray);
}

.newacy-section4-title {
    text-align: center;
}

.newacy-section4-title * {
    padding: 5px;
    background-color: var(--default-blue);
    width: 100%;
    color: #fff;
    font-weight: 600;
}

.newacy-section-4-tools-icon img {
    width: 80px;
}