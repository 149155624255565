.newacy-title-section-title-wrap {
    margin-bottom: 80px;
    margin-top: 80px;
}

.newacy-section-title h1,
.newacy-section-title h2,
.newacy-section-title h3,
.newacy-section-title h4,
.newacy-section-title h5,
.newacy-section-title h6 {
    color: var(--default-blue);
    font-weight: 800;
    text-align: center;
}

.newacy-section-subtitle h1,
.newacy-section-subtitle h2,
.newacy-section-subtitle h3,
.newacy-section-subtitle h4,
.newacy-section-subtitle h5,
.newacy-section-subtitle h6 {
    color: var(--default-gray);
    font-weight: 500;
    text-align: center;
}


/* red */

.newacy-section-title-red h1,
.newacy-section-title-red h2,
.newacy-section-title-red h3,
.newacy-section-title-red h4,
.newacy-section-title-red h5,
.newacy-section-title-red h6 {
    color: var(--default-red);
    font-weight: 800;
    text-align: center;
}