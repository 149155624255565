.newacy-section1-card-wrapper {
    position: relative;
    margin-bottom: 30px;
}

@media (min-width: 768px) {
    .newacy-section1 {
        min-height: 50px;
        padding-bottom: 50px;
        transition: all 2s;
    }
}