.newacy-section3-card-wrapper {
    height: 100%;
    padding: 15px;
}

.newacy-section3-card {
    border-radius: 20px;
    box-shadow: 0px 0px 10px -5px #333;
    padding: 35px;
    height: 100%;
}

.newacy-section3-card-title * {
    font-weight: 800;
    color: var(--default-red);
    text-align: center;
}

.newacy-section3-card-subtitle {
    text-align: justify;
    color: var(--dark-gray);
}

.newacy-section3-card-image-wrap img {
    margin-top: 30px;
    width: 100%;
    max-height: 100%;
    bottom: 0px;
}

@media (max-width:767px) {
    .newacy-section3-card-wrapper {
        height: 100%;
        padding: 15px 0px;
        margin: 0px -15px;
    }
}