@media (max-width: 767.9px) {
    /* menus */
    .newacy-navbar {
        display: none;
    }
    .hamburger-menu-bar-wrap {
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 8vh;
        min-height: 60px;
        max-height: 80px;
        overflow: hidden;
        z-index: 1;
        background-color: transparent;
        padding: 15px;
    }
    .hamburger-menu-logo-container {
        border-radius: 10px;
        text-align: center;
        max-height: 40px;
    }
    .hamburger-menu-logo-container img {
        width: auto;
        max-width: 100%;
        max-height: 40px;
    }
    .hamburger-menu-icon-container {
        text-align: right;
    }
    .hamburger-menu-body {
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        min-height: 100vh;
        background-color: transparent;
        z-index: var(--z-index-max);
    }
    .hamburger-menu-body-content {
        background-color: var(--default-blue);
        box-shadow: 0px 0px 10px 5px var(--default-blue);
        height: 100%;
    }
    .hamburger-menu-links {
        text-align: center;
        padding: 30px 10px;
    }
    .hamburger-menu-link-box {
        background-color: var(--default-red);
        border-radius: 20px;
        overflow: hidden;
        box-shadow: 0px 10px 10px -5px #32374e;
        padding: 20px 0px;
        margin-top: 15px;
    }
    .hamburger-menu-link-box a {
        color: #fff;
        font-weight: 700;
        text-decoration: none;
    }
    .hamburger-menu-link-box-white {
        background-color: #fff;
        border-radius: 20px;
        overflow: hidden;
        box-shadow: 0px 10px 10px -5px #32374e;
        padding: 20px 0px;
        margin-top: 15px;
    }
    .hamburger-menu-link-box-white a {
        color: var(--default-red);
        font-weight: 700;
        text-decoration: none;
    }
}