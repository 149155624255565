@media (min-width: 768px) {
     :root {
        --banner-height: 85vh;
        --banner-polygon: polygon(0 0, 0 100%, 100% 85%, 100% 0);
    }
    .newacy-banner {
        width: 100%;
        min-height: var(--banner-height);
    }
    .newacy-banner-wrapper,
    .newacy-banner-background-color,
    .newacy-banner-background-image,
    .newacy-banner-text-content-wrap,
    .newacy-banner-background-image-filter {
        width: 100%;
        height: var(--banner-height);
        background-size: 100% 100%;
    }
    .newacy-banner-background-color {
        background-color: var(--default-red);
        position: relative;
        -webkit-clip-path: var(--banner-polygon);
        clip-path: var(--banner-polygon);
    }
    .newacy-banner-background-image {
        position: relative;
    }
    .newacy-banner-background-image::before {
        position: absolute;
        top: -20px;
        left: -5%;
        width: 110%;
        height: 100%;
        content: '';
        background-size: 110% 110%;
        background-position: center center;
        background-position: center center;
        -webkit-clip-path: var(--banner-polygon);
        clip-path: var(--banner-polygon);
    }
    .newacy-banner-background-image-filter::before {
        position: absolute;
        top: -20px;
        left: 0;
        width: 100%;
        height: 100%;
        content: '';
        background-color: #0000002f;
        -webkit-clip-path: var(--banner-polygon);
        clip-path: var(--banner-polygon);
    }
    .newacy-banner-text-content-wrap {
        position: relative;
    }
    .newacy-banner-text-content {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .newacy-banner-text-content {
        color: #fff;
        width: 100%;
        text-align: center;
        padding: 15px;
    }
    .newacy-banner-text-content h1 {
        font-weight: 900;
        font-size: 45px;
        text-align: center;
        text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.842);
        -webkit-background-clip: text;
        -moz-background-clip: text;
        background-clip: text;
    }
    .newacy-banner-text-content h3 {
        font-weight: 600;
        font-size: 25px;
        text-align: center;
    }
}

@media (min-width: 996px) {
    .newacy-banner-text-content h1 {
        font-weight: 900;
        font-size: 60px;
        text-align: center;
    }
    .newacy-banner-text-content h3 {
        font-weight: 600;
        font-size: 30px;
        text-align: center;
    }
}

@media (min-width: 1200px) {
     :root {
        --banner-height: 85vh;
        --banner-polygon: polygon(0 0, 0 100%, 100% 85%, 100% 0);
    }
    .newacy-banner-text-content h1 {
        font-size: 65px;
    }
    .newacy-banner-text-content h3 {
        font-size: 40px;
    }
}