.newacy-contact-banner {
    width: 100%;
    height: 50vh;
    overflow: hidden;
    position: relative;
}

.newacy-contact-banner::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.34);
}

.newacy-contact-banner img {
    width: 110%;
    height: auto;
    vertical-align: middle;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -55%);
}

.newacy-contact-banner .newacy-contact-banner-title {
    width: 100%;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    font-size: 80px;
    font-weight: 900;
    text-transform: uppercase;
    z-index: 1;
    text-shadow: 0px 2px 3px rgba(0, 0, 0, 0.842);
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
}

@media (max-width:767px) {
    .newacy-contact-banner {
        width: 100%;
        height: 25vh;
        overflow: hidden;
        position: relative;
        margin-bottom: 0px;
    }
    .newacy-contact-banner::after {
        background-color: rgba(0, 0, 0, 0.35);
    }
    .newacy-contact-banner .newacy-contact-banner-title {
        position: absolute;
        width: 100%;
        text-align: center;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #fff;
        font-size: 40px;
        font-weight: 900;
        z-index: 1;
    }
}