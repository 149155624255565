.newacy-about-banner {
    width: 100%;
    height: 50vh;
    overflow: hidden;
    position: relative;
    margin-bottom: 50px;
}

.newacy-about-banner::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.54);
}

.newacy-about-banner img {
    width: 100%;
    height: auto;
    vertical-align: middle;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -45%);
}

.newacy-about-banner .newacy-about-banner-title {
    width: 100%;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    font-size: 80px;
    font-weight: 900;
    text-transform: uppercase;
    z-index: 1;
    text-shadow: 0px 2px 3px rgba(0, 0, 0, 0.842);
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
}

.about-page-content-title-section {
    margin-bottom: 50px;
}

.about-page-title {
    text-align: center;
    font-size: 40px;
    color: var(--default-blue);
    font-weight: 900;
    text-transform: uppercase;
    margin: 40px 0px;
}

.about-page-subtitle {
    text-align: center;
    font-size: 30px;
    color: var(--default-blue);
    font-weight: 500;
}

.about-page-content-description-section {
    margin-bottom: 50px;
    font-size: 25px;
}

.about-page-content-description-section a {
    color: var(--default-blue);
    text-decoration: none;
    font-weight: 600;
}

@media (max-width:767px) {
    .newacy-about-banner {
        width: 100%;
        height: 25vh;
        overflow: hidden;
        position: relative;
        margin-bottom: 30px;
    }
    .newacy-about-banner::after {
        background-color: rgba(0, 0, 0, 0.35);
    }
    .newacy-about-banner .newacy-about-banner-title {
        position: absolute;
        width: 100%;
        text-align: center;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #fff;
        font-size: 40px;
        font-weight: 900;
        z-index: 1;
    }
    .about-page-subtitle {
        text-align: center;
        font-size: 20px;
        color: var(--default-blue);
        font-weight: 500;
    }
    .about-page-content-description-section {
        margin-bottom: 30px;
        font-size: 20px;
    }
}